import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Iframe from 'react-iframe';
import CloseIcon from '@material-ui/icons/Close';

import {getClassName,getUrl} from 'helpers/header';
import { Link,useLocation } from 'react-router-dom';
import terminalIcon from 'assets/img/terminalicon.svg';
import { HeaderContext } from 'contexts/HeaderContext';
import useWindowDimensions from 'hooks/useWindowDimensions';


import useStyles from './headerStyle';
type MoneyProps = {
  [key: string]: number;
};
const Header = (props: any): JSX.Element => {
  const classes = useStyles();
  const [reduceFrameSize, setFrameSize] = useState(false);
  const location = useLocation();
  const wrapperRef: any = useRef(null);
  const [switchState, setSwitchState] = useState(true);
  const { state, dispatch } = useContext(HeaderContext);
  const [terminalWindow, setTerminalWindow] = useState(false);
  const { width } = useWindowDimensions();
  const [currentMoneyType, setCurrentMoneyType] = useState('BILL_ACCEPTOR');
  const [moneyAccepterIndex, setMoneyAccepterIndex] = useState<any>(0);
  const [money, setMoney] = useState<MoneyProps>({
    coin: 0,
    cash: 0,
    amount: 1,
  });
  const { coin, cash, amount } = money;
  const {
    terminalStatus,
    terminalInfo,
    terminalID,
    isCoreRequestFinished,
    isConnectedToSocket,
    isConnectedToServer,
    commandResult,
    sendCommand,
    isTerminalWindowExpanded,
  }: any = state;

  useEffect(() => {
    if (width! <= 1280 && isTerminalWindowExpanded) {
      setFrameSize(true);
      return;
    }
    setFrameSize(false);
  }, [isTerminalWindowExpanded, width]);

  useEffect((): any => {
    dispatch({
      type: 'SET_IS_CONNECTED_TO_SERVER',
      status: isConnectedToSocket || false,
    });
  }, [isConnectedToSocket, dispatch]);

  useEffect(() => {
    sendCommand(JSON.stringify({ type: 'GET_STATE' }));
  }, [sendCommand, terminalStatus]);

  useEffect(() => {
    if (commandResult.result && Object.keys(terminalInfo).length <= 0) {
      dispatch({
        type: 'SET_TERMINAL_INFO',
        terminalInfo: commandResult.result,
      });
    }
  }, [commandResult, dispatch, terminalInfo, terminalStatus]);

  useEffect((): void => {
    if (isConnectedToServer === false && terminalStatus) {
      setSwitchState(false);
    }
  }, [isConnectedToServer, terminalStatus]);

  const toggleFrame = useCallback(
    (status: boolean) => {
      dispatch({
        type: 'SET_TERMINAL_WINDOW',
        isTerminalWindowExpanded: status,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (isTerminalWindowExpanded) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          toggleFrame(!isTerminalWindowExpanded);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isTerminalWindowExpanded, toggleFrame]);

  const handleChange = (event: any) => {
    const { id, value } = event.target;

    setMoney({ ...money, [id]: Number(value) });
  };

  const handleAddMoney = () => {
    const selectElement: any = document.getElementsByClassName(
      'MuiSelect-select'
    )[0];
    const defaultSelectValue =
      selectElement.options[selectElement.selectedIndex].value;
    if (coin !== 0 || cash !== 0) {
      sendCommand(
        JSON.stringify({
          type: 'INSERT_MONEY',
          payload: {
            acceptorType: currentMoneyType,
            nominal: cash || coin,
            count: amount,
          },
        })
      );
    }

    if (coin === 0 && cash === 0) {
      sendCommand(
        JSON.stringify({
          type: 'INSERT_MONEY',
          payload: {
            acceptorType: currentMoneyType,
            nominal: Number(defaultSelectValue),
            count: amount,
          },
        })
      );
    }
  };
  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSwitchState(isChecked);

    if (isChecked) {
      sendCommand(JSON.stringify({ type: 'TURN_ON' }));
    } else {
      sendCommand(JSON.stringify({ type: 'TURN_OFF' }));
    }
  };

  const handleChangeRadio = (e: any) => {
    setMoney({
      coin: 0,
      cash: 0,
      amount: 1,
    });

    setMoneyAccepterIndex(Number(e.target.name));
    setCurrentMoneyType(e.target.value);
  };

  console.log({ moneyAccepterIndex, money });

  return (
    <AppBar position="fixed" className={classes.appBar}>
      {location.pathname !== '/admin/control' && (
        <Fab
          color="primary"
          variant="extended"
          className={classes.fabTerminal}
          onClick={() => setTerminalWindow(!terminalWindow)}
        >
          {!terminalWindow ? 'Открыть терминал' : <CloseIcon />}
        </Fab>
      )}
      <div
        className={`${classes.terminalWindow} ${
          terminalWindow ? classes.displayTerminalWindow : ''
        } ${getClassName(location,classes,isTerminalWindowExpanded)} `}
      >
        <div ref={wrapperRef} className={classes.terminalWindowWrapper}>
          <Iframe
            url={getUrl(isCoreRequestFinished,terminalStatus,terminalID)

            }
            width="1030px"
            height="770px"
            allowFullScreen
            className={`${reduceFrameSize && classes.frameSmallSize}`}
          />
          {!isTerminalWindowExpanded && location.pathname === '/admin/control' && (
            <Tooltip title="Открыть в большом окне">
              <div
                className={classes.clickWrapper}
                onClick={() => toggleFrame(!isTerminalWindowExpanded)}
              ></div>
            </Tooltip>
          )}
          {isTerminalWindowExpanded && (
            <div className={classes.moneyAccepter}>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={currentMoneyType}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value="BILL_ACCEPTOR"
                  name="0"
                  control={<Radio />}
                  label="Купюры"
                  onChange={handleChangeRadio}
                />
                <FormControlLabel
                  value="COIN_ACCEPTOR"
                  name="1"
                  control={<Radio />}
                  label="Монеты"
                  onChange={handleChangeRadio}
                />
              </RadioGroup>
              <div>
                <FormControl>
                  <InputLabel htmlFor="demo-dialog-native">Номинал</InputLabel>
                  {console.log(
                    'moneyacc',
                    moneyAccepterIndex !== undefined &&
                      terminalInfo.equipment &&
                      terminalInfo.equipment[moneyAccepterIndex]
                        ._supportedNominal[0]
                  )}
                  <Select
                    native
                    id={currentMoneyType === 'BILL_ACCEPTOR' ? 'cash' : 'coin'}
                    defaultValue={
                      moneyAccepterIndex !== undefined &&
                      terminalInfo.equipment &&
                      terminalInfo.equipment[moneyAccepterIndex]
                        ._supportedNominal[0]
                    }
                    onChange={handleChange}
                    input={<Input id="demo-dialog-native" />}
                    className={classes.selectWrapper}
                  >
                    {moneyAccepterIndex !== undefined &&
                      terminalInfo.equipment &&
                      terminalInfo.equipment[
                        moneyAccepterIndex
                      ]._supportedNominal.map((item: any, idx: any) => {
                        return (
                          <option key={idx} value={item}>
                            {item}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl>
                  <TextField
                    id="amount"
                    label="Количество"
                    className={classes.modalInput}
                    value={amount}
                    type="number"
                    onChange={(e) => handleChange(e)}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </FormControl>
              </div>
              <Button onClick={handleAddMoney} color="primary">
                Внести
              </Button>
            </div>
          )}

          <CloseIcon
            onClick={() => toggleFrame(!isTerminalWindowExpanded)}
            className={classes.closeIcon}
          />
        </div>
      </div>
      <div className={classes.mobileTerminalWindow}>
        <Iframe
          url={
            isCoreRequestFinished && terminalStatus
              ? `/shell/shell.htm?terminal_id=${terminalID}`
              : '/shell/notwork.htm'
          }
          width="1030px"
          height="770px"
          allowFullScreen
          className={`${reduceFrameSize && classes.frameSmallSize}`}
        />
      </div>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/">
          <img
            src={terminalIcon}
            style={{ marginRight: '15px' }}
            alt="terminal"
          />
        </Link>
        <Typography variant="h6" noWrap className={classes.title}>
          Админ-панель имитатора терминала
        </Typography>

        <span className={classes.statusWrapper}>
          Статус:{' '}
          <div
            className={`${classes.circle} ${
              state.isConnectedToServer
                ? classes.statusSuccess
                : classes.statusFailed
            }`}
          />
          <FormControlLabel
            control={
              <Switch
                value="checkedA"
                color="secondary"
                onChange={(e) => switchHandler(e)}
                checked={terminalStatus}
                className={classes.switch}
              />
            }
            labelPlacement="start"
            label={switchState ? 'On' : 'Off'}
          />
        </span>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
