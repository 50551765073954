import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Routes from 'routes';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'theme';
import { HeaderProvider } from 'contexts/HeaderContext';
import { RequestsProvider } from 'contexts/RequestsContext';

export const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <HeaderProvider>
        <RequestsProvider>
          <Routes />
        </RequestsProvider>
      </HeaderProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
