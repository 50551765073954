/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { HeaderContext } from 'contexts/HeaderContext';
import { useSocket } from 'hooks';

const Footer = () => {
  const { state, dispatch } = useContext(HeaderContext);
  const { isConnected, commandResult, sendRequest, sendCommand } = useSocket();
  const {
    sendRequest: savedSendRequest,
    sendCommand: savedSendCommand,
  } = state;

  useEffect(() => {
    if (typeof isConnected !== 'undefined') {
      dispatch({
        type: 'SET_IS_CONNECTED_TO_SOCKET',
        isConnectedToSocket: isConnected,
      });
    }
  }, [isConnected]);

  useEffect(() => {
    if (typeof commandResult !== 'undefined') {
      dispatch({ type: 'SET_COMMAND_RESULT', commandResult });
    }
  }, [commandResult]);

  useEffect(() => {
    if (
      typeof isConnected !== 'undefined' &&
      typeof sendRequest !== 'undefined' &&
      isConnected
    ) {
      dispatch({ type: 'SET_SEND_REQUEST_FUNC', sendRequest });
    }
  }, [isConnected, savedSendRequest]);

  useEffect(() => {
    if (
      typeof isConnected !== 'undefined' &&
      typeof sendCommand !== 'undefined' &&
      isConnected
    ) {
      dispatch({ type: 'SET_SEND_COMMAND_FUNC', sendCommand });
    }
  }, [isConnected, savedSendCommand]);

  return <div />;
};

export default Footer;
