/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import { useHistory } from 'react-router-dom';

import { HeaderContext } from 'contexts/HeaderContext';
import { RequestsContext } from 'contexts/RequestsContext';
import { WEBSOCKET_URL } from 'constants/api';
import {
  checkSocketConnectionEventType,
  checkSocketConnectionCommandType,
} from 'helpers/socket';

const useSocket = () => {
  const [terminalId, setTerminalId] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [hasWsEvents, setHasWsEvents] = useState(false);
  const [socketConnection, setSocketConnection] = useState<any>(null);
  const [commandResult, setCommandResult] = useState<any>({});
  const { dispatch: headerDispatch } = useContext(HeaderContext);
  const { requestsDispatch } = useContext(RequestsContext);
  const history = useHistory();
  window['socketConnection'] = socketConnection;
  let setTimeOutIndex: any = null;

  useEffect(() => {
    const socket = io(WEBSOCKET_URL);

    setSocketConnection(socket);
  }, []);

  useEffect(() => {
    if (socketConnection !== null) {
      socketConnection.on('connect', () => {
        if (!isConnected) setIsConnected(true);
      });
    }
  }, [socketConnection]);

  useEffect(() => {
    if (terminalId) {
      headerDispatch({ type: 'SET_TERMINAL_ID', terminalId: terminalId });
    }
  }, [terminalId]);

  useEffect(() => {
    if (isConnected && socketConnection && typeof terminalId === 'undefined') {
      socketConnection.on('CREATE_TERMINAL', (data: any) => {
        const { id } = data.result;

        if (id) {
          setTerminalId(id);
          sendCommand(JSON.stringify({ type: 'TURN_ON' }));
          history.push('/admin/control');
        }
      });
    }
  }, [isConnected, socketConnection, terminalId]);

  useEffect(() => {
    if (isConnected && socketConnection && terminalId && !hasWsEvents) {
      socketConnection.on('COMMAND', (message: any) => {
        const {
          command: { type },
          err,
          result,
        } = message;

        if (err == null) {
          setCommandResult(message);

          checkSocketConnectionCommandType(
            type,
            result,
            headerDispatch,
            requestsDispatch
          );
        } else {
          headerDispatch({
            type: 'SET_TERMINAL_ERROR',
            isTerminalError: true,
            errorMessage: err,
          });
        }
      });

      socketConnection.on(
        'EVENT',
        (data: any, callback: (status: boolean) => void) => {
          const { type } = data;

          if (setTimeOutIndex === null) {
            headerDispatch({
              type: 'SET_EVENT_FIRED',
              isEventFired: true,
            });
            setTimeOutIndex = setTimeout(() => {
              headerDispatch({
                type: 'SET_EVENT_FIRED',
                isEventFired: false,
              });
              setTimeOutIndex = null;
            }, 2000);
          }
          checkSocketConnectionEventType(
            type,
            data,
            headerDispatch,
            requestsDispatch
          );
        }
      );

      socketConnection.on('CONNECT_TO_TERMINAL', (payload: any) => {
        console.log('payload CONNECT_TO_TERMINAL', payload);
        if (payload.result) setIsConnected(true);
      });

      socketConnection.on('connect', () => {
        console.log('CONNECT. CONNECT_TO_TERMINAL', terminalId);
        socketConnection.emit('CONNECT_TO_TERMINAL', {
          id: terminalId,
          isServer: true,
        });
      });

      socketConnection.on('reconnect', (attemptNumber: any) => {
        console.log('EVENT reconnect: ', attemptNumber);
      });

      socketConnection.on('reconnect_attempt', (attemptNumber: any) => {
        console.log('EVENT reconnect_attempt: ', attemptNumber);
      });

      socketConnection.on('reconnecting', (attemptNumber: any) => {
        console.log('EVENT reconnecting: ', attemptNumber);
      });

      socketConnection.on('reconnect_error', (error: any) => {
        console.log('EVENT reconnect_error: ', error);
      });

      socketConnection.on('reconnect_failed', () => {
        console.log('EVENT reconnect_failed');
      });

      socketConnection.on('disconnect', () => {
        console.log('disconnect');
        setIsConnected(false);
      });

      setHasWsEvents(true);
    }
  }, [isConnected, socketConnection, terminalId, hasWsEvents]);

  const sendCommand = useCallback(
    (payload: any) => {
      if (isConnected) {
        socketConnection.emit('COMMAND', payload);
      } else {
        console.log('socket disConnected');
      }
    },
    [isConnected]
  );

  const sendRequest = useCallback(
    (type: string, theme: any) => {
      if (isConnected) {
        socketConnection.emit(type, { theme });
      } else {
        console.log('socket disConnected');
      }
    },
    [isConnected]
  );

  return { isConnected, commandResult, sendRequest, sendCommand };
};

export default useSocket;
