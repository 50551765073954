import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const loaderStyle = makeStyles((theme: Theme) =>
  createStyles({
    loaderComponent: {
      // marginTop: '20%',
      border: '5px solid #ffd500',
      borderTop: '5px solid #555',
      borderRadius: '50%',
      width: '60px',
      height: '60px',
      animation: `$spin 2s linear infinite`,
      zIndex: 9999, // z-index не применяется
    },

    loaderWrapper: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      position: 'fixed',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      background: 'rgba(0, 0, 0, 0.72)',
      zIndex: 99999, // z-index не применяется
      '& h3': {
        position: 'fixed',
        fontWeight: '600',
        fontSize: '26px',
        lineHeight: '36px',
        color: '#FFFFFF',
        top: '55%',
      },
      '& span': {
        fontSize: '15px',
        lineHeight: '24px',
        color: '#FFFFFF',
      },
      '& p': {
        padding: '0',
        margin: '0',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
    logoSpinner: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
  })
);

export default loaderStyle;
