import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const reviewStyle = makeStyles((theme: Theme) =>
  createStyles({
    equipmentWrapper: {
      display: 'flex',
    },

    equipmentView: {
      width: '40%',
      border: '1px solid black',
      display: 'flex',
      flexFlow: 'column',
      padding: '20px',
    },
    equipmentRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    chipElement:{
      '&.MuiChip-root':{

        flexDirection: 'row-reverse',
        padding: '5px',
        '& .MuiSvgIcon-root.MuiChip-icon':{
          marginRight:'0'
        }
      },
    },

    tableWrapper:{
      '& .MuiTableRow-root': {
        verticalAlign: 'top'
      },
      '& .MuiTableCell-root':{
        padding:'16px 40px 16px 16px'
      }
    },
    reviewTableContainer:{

      maxWidth: '600px'
    },
    nominalWrapper:{
      display:'flex',
      flexFlow:'column',
      marginTop: '12px'
    },
    nominalRowWrapper:{
      position:'relative'
    },
    nominalCellIcon:{
      position:'absolute',
      top: '21px',
      right: '7px',
      transition: 'transform 0.6s ease',
    },
    rotate: {
    transform: 'rotate(180deg)'
  },
    nominalRow:{
      display:'flex',
      justifyContent: 'space-between',
      margin: '0px 15px'
    }
  })
);

export default reviewStyle;
