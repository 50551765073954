import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const entryPageStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      '& .makeStyles-grid-5': {
        margin: '0',
      },
    },
    entryButton: {
      background: '#ffde16',
      '&:hover': {
        background: '#f0d94c',
      },
      '&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)': {
        borderRight: '1px solid #d6c03e',
      },
    },
    terminalBlock: {
      // textAlign: 'center',
    },
    terminalImage: {
      [theme.breakpoints.up('xs')]: {
        width: '25%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '55%',
      },
      [theme.breakpoints.up('md')]: {
        width: '55%',
      },
      height: '100%',
    },
    paper: {
      zIndex: 1,
      position: 'relative',
      margin: theme.spacing(1),
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default entryPageStyle;
