import React, { createContext, useReducer, Dispatch } from 'react';
import MainReducer from 'reducers/header';

type InitialStateType = {
  isConnectedToServer: boolean,
  isConnectedToSocket: boolean,
  isTerminalCreated: boolean,
  isTerminalEnabled: boolean,
  showLoader: boolean,
  terminalID: string,
  commandResult: any,
  sendRequest: any,
  sendCommand: any,
  terminalStatus: boolean,
  isCoreRequestFinished: boolean,
  isTerminalWindowExpanded: boolean,
  payloadReceiptBase64: string,
  showReceipt: boolean,
  isTerminalError: boolean,
  errorMessage: string,
  isEventFired: boolean,
  isSoundPlaying: string,
  soundUrl: string,
  isSoundDisabled: boolean,
  terminalInfo: {
    [key: string]: Array<{}>,
  },
};

const initialState = {
  isConnectedToServer: false,
  isConnectedToSocket: false,
  isTerminalCreated: false,
  isTerminalEnabled: false,
  terminalStatus: false,
  showLoader: false,
  terminalID: '',
  commandResult: {},
  isCoreRequestFinished: false,
  isTerminalWindowExpanded: false,
  terminalInfo: {},
  printReceipt: false,
  showReceipt: false,
  isTerminalError: false,
  errorMessage: '',
  payloadReceiptBase64: '',
  isSoundPlaying: 'STOPPED',
  soundUrl: '',
  isEventFired: false,
  isSoundDisabled: false,
  sendRequest: () => null,
  sendCommand: () => null,
};

const HeaderContext = createContext<{
  state: InitialStateType,
  dispatch: Dispatch<any>,
}>({
  state: initialState,
  dispatch: () => null,
});

const HeaderProvider: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(MainReducer, initialState);

  return (
    <HeaderContext.Provider value={{ state, dispatch }}>
      {props.children}
    </HeaderContext.Provider>
  );
};

export { HeaderProvider, HeaderContext };
