export const checkSocketConnectionEventType = (
  type: string,
  data: any,
  headerDispatch: any,
  requestsDispatch: any
) => {
  switch (type) {
    case 'PLAY_SOUND': {
      const { file } = data.payload;
      headerDispatch({
        type: 'SET_PLAY_SOUND',
        isSoundPlaying: 'PLAYING',
        soundUrl: file,
      });
      break;
    }
    case 'STOP_SOUND': {
      headerDispatch({
        type: 'SET_PLAY_SOUND',
        isSoundPlaying: 'STOPPED',
        soundUrl: '',
      });
      break;
    }
    case 'PRINT_CHECK': {
      headerDispatch({
        type: 'SET_TERMINAL_WINDOW',
        isTerminalWindowExpanded: false,
      });
      headerDispatch({
        type: 'SET_PAYLOAD_RECEIPT',
        payloadReceiptBase64: data.payload.dataUrl,
      });
      headerDispatch({
        type: 'SET_PRINT_RECEIPT',
        printReceipt: true,
      });
      break;
    }
    case 'CLOSE_SYSTEM_KEYBOARD': {
      headerDispatch({
        type: 'SET_PRINT_RECEIPT',
        printReceipt: false,
      });
      headerDispatch({ type: 'SET_RECEIPT_PRINT', showReceipt: false });
      setTimeout(() => {
        headerDispatch({
          type: 'SET_PAYLOAD_RECEIPT',
          payloadReceiptBase64: '',
        });
      }, 3000);
      break;
    }
    case 'CORE_REQUESTS_FINISHED': {
      headerDispatch({
        type: 'SET_CORE_REQUESTS_FINISHED',
        isCoreRequestFinished: true,
      });
      break;
    }
    case 'HIDE_ALL_BUTTONS': {
      requestsDispatch({
        type: 'MONEY_WAS_INSERTED',
        moneyWasInserted: true,
      });
      break;
    }
    default:
      break;
  }
};

export const checkSocketConnectionCommandType = (
  type: string,
  result: any,
  headerDispatch: any,
  requestsDispatch: any
) => {
  switch (type) {
    case 'TURN_ON': {
      headerDispatch({
        type: 'SET_TERMINAL_STATUS',
        terminalStatus: true,
      });
      break;
    }
    case 'TURN_OFF': {
      headerDispatch({
        type: 'SET_TERMINAL_STATUS',
        terminalStatus: false,
      });
      headerDispatch({
        type: 'SET_CORE_REQUESTS_FINISHED',
        isCoreRequestFinished: false,
      });
      break;
    }
    case 'GET_REQUEST_LIST': {
      requestsDispatch({
        type: 'ADD_REQUEST',
        requests: result,
      });
      break;
    }
    default:
      break;
  }
};
