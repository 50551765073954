import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Help from '@material-ui/icons/HelpOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DnsIcon from '@material-ui/icons/Dns';
import SendIcon from '@material-ui/icons/Send';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { DRAWER_WIDTH } from 'constants/common';
import terminalIcon from 'assets/img/terminalicon.svg';

const defaultMenuItems = [
  { title: 'Управление', icon: SportsEsportsIcon, name: 'control' },
  { title: 'Оборудование', icon: VisibilityIcon, name: 'review' },
  { title: 'Запросы', icon: SendIcon, name: 'requests' },
  { title: 'Помощь', icon: Help, name: 'help' },
];

const menuItems =
  process.env.NODE_ENV === 'production'
    ? defaultMenuItems
    : [
        { title: 'Управление', icon: SportsEsportsIcon, name: 'control' },
        { title: 'Оборудование', icon: VisibilityIcon, name: 'review' },
        { title: 'Запросы', icon: SendIcon, name: 'requests' },
        { title: 'Помощь', icon: Help, name: 'help' },
        { title: 'Состояние', icon: DnsIcon, name: 'equipment' },
        { title: 'Запрос - ответ (Test)', icon: SendIcon, name: 'test' },
      ];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    toolbarMobile: {
      padding: '15px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
  })
);

interface LeftMenuProps extends RouteComponentProps {
  handleDrawerToggle(): void;
  mobileOpen: boolean;
}

const LeftMenu: FunctionComponent<LeftMenuProps> = (props): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState(0);
  const container = window !== undefined ? () => document.body : undefined;
  const classes = useStyles();
  const theme = useTheme();
  const toolbarClasses = classNames({
    [classes.toolbar]: true,
    [classes.toolbarMobile]: props.mobileOpen,
  });

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedItem(index);
  };

  const drawer = (
    <div>
      <div className={toolbarClasses}>
        {props.mobileOpen && (
          <>
            <img
              src={terminalIcon}
              style={{ marginRight: '15px' }}
              alt="terminal"
            />
            <span>Имитатор терминала</span>
          </>
        )}
      </div>
      <Divider />
      <List className={classes.toolbar}>
        {menuItems.map(({ title, icon: MenuIcon, name }, i) => (
          <Link to={`${props.match.url}/${name}`} key={title}>
            <ListItem
              button
              onClick={(event) => handleListItemClick(event, i + 1)}
              selected={selectedItem === i + 1}
            >
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default withRouter(LeftMenu);
