/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Sound from 'react-sound';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import useStyles from './ControlStyle';
import Input from '@material-ui/core/Input';
import { HeaderContext } from 'contexts/HeaderContext';
import { RequestsContext } from 'contexts/RequestsContext';
import CloseIcon from '@material-ui/icons/Close';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
type MoneyProps = {
  [key: string]: number,
};

const Control = (): JSX.Element => {
  const { state, dispatch } = useContext(HeaderContext);
  const [open, setOpen] = useState(false);
  const [urlChanges, setUrlChanges] = useState(false);
  const [money, setMoney] = useState<MoneyProps>({
    coin: 0,
    cash: 0,
    amount: 1,
  });
  const [currentMoneyType, setCurrentMoneyType] = useState();
  const [moneyAccepterIndex, setMoneyAccepterIndex] = useState<any>();
  const [coinSoundPlayStatus, setCoinSoundPlayStatus] = useState<any>('PAUSED');
  const [billSoundPlayStatus, setBillSoundPlayStatus] = useState<any>('PAUSED');
  const { state: requestState, requestsDispatch } = useContext(RequestsContext);
  const { moneyWasInserted } = requestState;
  const classes = useStyles();
  const {
    terminalInfo,
    terminalStatus,
    sendCommand,
    showReceipt,
    printReceipt,
    payloadReceiptBase64,
    isEventFired,
    isSoundPlaying,
    soundUrl,
    isSoundDisabled,
  }: any = state;
  const { coin, cash, amount } = money;

  useEffect(() => {
    if (moneyWasInserted) {
      playSound();
      requestsDispatch({
        type: 'MONEY_WAS_INSERTED',
        moneyWasInserted: false,
      });
    }
  }, [moneyWasInserted]);

  useEffect(() => {
    setUrlChanges(true);
  }, [soundUrl]);

  useEffect(() => {
    dispatch({ type: 'DISABLE_LOADER' });
  }, [dispatch]);

  const handleChange = (event: any) => {
    const { id, value } = event.target;

    setMoney({ ...money, [id]: Number(value) });
  };

  const switchHandler = () => {
    if (!terminalStatus) {
      sendCommand(JSON.stringify({ type: 'TURN_ON' }));
    } else {
      sendCommand(JSON.stringify({ type: 'TURN_OFF' }));
    }
  };

  const handleClickModal = (e: any) => {
    setMoney({
      coin: 0,
      cash: 0,
      amount: 1,
    });

    for (let i = 0; i < terminalInfo.equipment.length; i++) {
      const element: any = terminalInfo.equipment[i];
      if (element.type === e.target.id) {
        setMoneyAccepterIndex(i);
        setCurrentMoneyType(element.type);
      }
    }

    setOpen(!open);
  };
  const toggleSoundButton = (status: boolean) => {
    dispatch({
      type: 'SET_SOUND_DISABLE',
      isSoundDisabled: status,
    });
  };

  const displayTotalAmount = () => {
    const sum: number[] = [];
    if (coin !== 0 || (cash !== 0 && amount !== 1)) {
      Object.keys(money).forEach((item) => {
        if (money[item] !== 0) {
          sum.push(money[item]);
        }
      });
    }

    return (
      // если номинал пустой , возвращаем значения по дефолту
      Number(sum[0]) * Number(sum[1]) ||
      (moneyAccepterIndex !== undefined &&
        terminalInfo.equipment[moneyAccepterIndex]._supportedNominal[0] *
          amount)
    );
  };

  const setShowReceipt = (status: boolean) => {
    dispatch({ type: 'SET_RECEIPT_PRINT', showReceipt: status });
  };

  const playSound = () => {
    if (currentMoneyType === 'BILL_ACCEPTOR') {
      setBillSoundPlayStatus('PLAYING');
    }

    if (currentMoneyType === 'COIN_ACCEPTOR') {
      setCoinSoundPlayStatus('PLAYING');
    }
  };

  const handleAddMoney = () => {
    const selectElement: any = document.getElementsByClassName(
      'MuiSelect-select'
    )[0];
    const defaultSelectValue =
      selectElement.options[selectElement.selectedIndex].value;

    if (coin !== 0 || cash !== 0) {
      sendCommand(
        JSON.stringify({
          type: 'INSERT_MONEY',
          payload: {
            acceptorType: currentMoneyType,
            nominal: cash || coin,
            count: amount,
          },
        })
      );
    }

    if (coin === 0 && cash === 0) {
      sendCommand(
        JSON.stringify({
          type: 'INSERT_MONEY',
          payload: {
            acceptorType: currentMoneyType,
            nominal: Number(defaultSelectValue),
            count: amount,
          },
        })
      );
    }
    setOpen(false);
  };

  const handleSongFinishedPlaying = (): void => {
    setBillSoundPlayStatus('STOPPED');
    setCoinSoundPlayStatus('STOPPED');
  };

  return (
    <div>
      <div className={classes.terminalWrapper}>
        <div className={classes.Terminal}>
          <div className={classes.mainScreen}></div>
          <Tooltip
            title={terminalStatus ? 'Выключить терминал' : 'Включить терминал'}
          >
            <div className={classes.power} onClick={() => switchHandler()}>
              <div className={classes.powerLight}></div>
            </div>
          </Tooltip>
          <div
            className={`${classes.flashLight} ${
              isEventFired && classes.blinkAnimation
            }`}
          ></div>
          <Tooltip title={isSoundDisabled ? 'включить звук' : 'выключить звук'}>
            <div
              onClick={() => toggleSoundButton(!isSoundDisabled)}
              className={classes.soundControlButton}
            >
              {isSoundDisabled ? (
                <VolumeOffIcon className={classes.soundControlIcon} />
              ) : (
                <VolumeUpIcon className={classes.soundControlIcon} />
              )}
            </div>
          </Tooltip>
          <Tooltip title="Принтер чека">
            <div
              className={classes.printer}
              onClick={() =>
                payloadReceiptBase64 !== '' && setShowReceipt(!showReceipt)
              }
            >
              <div
                className={`${classes.paperHolder} ${
                  printReceipt && classes.print
                } ${printReceipt && classes.paperHolderAnimation}`}
              >
                <div className={`${classes.paper} ${classes.receiptIcon}`}>
                  <img src={`${payloadReceiptBase64}`} alt="receiptIcon" />
                </div>
              </div>
            </div>
          </Tooltip>
          {!isSoundDisabled && (
            <Sound
              url={`${process.env.PUBLIC_URL}/static/coinAcceptor.wav`}
              playStatus={coinSoundPlayStatus}
              onFinishedPlaying={handleSongFinishedPlaying}
            />
          )}

          <Tooltip title="Монетоприемник">
            <div
              className={classes.coin}
              id="COIN_ACCEPTOR"
              onClick={(e) => handleClickModal(e)}
            />
          </Tooltip>

          {!isSoundDisabled && (
            <Sound
              url={`${process.env.PUBLIC_URL}/static/billAcceptor.wav`}
              playStatus={billSoundPlayStatus}
              onFinishedPlaying={handleSongFinishedPlaying}
            />
          )}

          <Tooltip title="Купюроприемник">
            <div
              className={classes.cash}
              id="BILL_ACCEPTOR"
              onClick={(e) => handleClickModal(e)}
            />
          </Tooltip>
        </div>
        <div className={classes.terminalFlat}></div>

        <div>
          {!isSoundDisabled && urlChanges && (
            <Sound
              url={`${process.env.PUBLIC_URL}/shell/${soundUrl}`}
              playStatus={isSoundPlaying}
              onFinishedPlaying={() => setUrlChanges(false)}
            />
          )}

          <Dialog
            open={showReceipt}
            onClose={() => setShowReceipt(!showReceipt)}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent className={classes.contentReceiptWrapper}>
              <img src={`${payloadReceiptBase64}`} alt="dialogImg" />
            </DialogContent>
            <CloseIcon
              onClick={() => setShowReceipt(!showReceipt)}
              className={classes.closeIcon}
            />
          </Dialog>

          <Dialog
            open={open}
            onClose={(e) => handleClickModal(e)}
            aria-labelledby="form-dialog-title"
            className={classes.modalWrapper}
          >
            <DialogTitle id="form-dialog-title">
              {currentMoneyType === 'BILL_ACCEPTOR'
                ? 'Купюроприёмник'
                : 'Монетоприёмник'}{' '}
              укажите сумму
            </DialogTitle>
            <DialogContent className={classes.contentWrapper}>
              <FormControl>
                <InputLabel htmlFor="demo-dialog-native">Номинал</InputLabel>
                <Select
                  native
                  id={currentMoneyType === 'BILL_ACCEPTOR' ? 'cash' : 'coin'}
                  defaultValue={
                    moneyAccepterIndex !== undefined &&
                    terminalInfo.equipment[moneyAccepterIndex]
                      ._supportedNominal[0]
                  }
                  onChange={handleChange}
                  input={<Input id="demo-dialog-native" />}
                  className={classes.selectWrapper}
                >
                  {moneyAccepterIndex !== undefined &&
                    terminalInfo.equipment[
                      moneyAccepterIndex
                    ]._supportedNominal.map((item: any, idx: any) => {
                      return (
                        <option key={idx} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl>
                <TextField
                  id="amount"
                  label="Количество"
                  className={classes.modalInput}
                  value={amount}
                  type="number"
                  onChange={(e) => handleChange(e)}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions className={classes.dialogActionWrapper}>
              Всего: {displayTotalAmount()}
              <div>
                <Button onClick={handleAddMoney} color="primary">
                  Внести
                </Button>
                <Button onClick={() => setOpen(!open)} color="secondary">
                  Отмена
                </Button>
              </div>
            </DialogActions>
            <CloseIcon
              onClick={() => setOpen(!open)}
              className={classes.closeIcon}
            />
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Control;
