import React, { ReactNode, forwardRef } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import useStyles from './buttonStyle';

interface RegularButtonProps {
  children: ReactNode;
  color: ColorType;
  size?: SizeType;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  simple?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  className?: string | any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

type SizeType = 'sm' | 'lg';
type ColorType =
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'rose'
  | 'white'
  | 'facebook'
  | 'twitter'
  | 'google'
  | 'github'
  | 'transparent';

const RegularButton = forwardRef(
  (props: RegularButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size = 'sm',
      block,
      link,
      justIcon,
      className,
      onClick,
      ...rest
    } = props;

    const classes = useStyles();

    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
    });

    return (
      <Button {...rest} ref={ref} className={btnClasses} onClick={onClick}>
        {children}
      </Button>
    );
  }
);

export default RegularButton;
