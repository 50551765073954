import React, { useReducer, createContext, FC, Dispatch } from 'react';
import requestsReducer from 'reducers/requests';

type RequestType = {
  offset: number,
  limit: number,
  count: number,
  data: RowType[],
};

type RowType = {
  type: number;
  request: string;
  response: string;
  timestamp?: number;
};

type InitialStateType = {
  requests: RequestType,
  moneyWasInserted: boolean,
};

const initialState = {
  requests: {
    offset: 0,
    limit: 10,
    count: 0,
    data: [],
  },
  moneyWasInserted: false,
};

const RequestsContext = createContext<{
  state: InitialStateType,
  requestsDispatch: Dispatch<any>,
}>({
  state: initialState,
  requestsDispatch: () => null,
});

const RequestsProvider: FC = (props: any) => {
  const [state, requestsDispatch] = useReducer(requestsReducer, initialState);

  return (
    <RequestsContext.Provider value={{ state, requestsDispatch }}>
      {props.children}
    </RequestsContext.Provider>
  );
};

export { RequestsProvider, RequestsContext };
