import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { defaultFont } from 'assets/jss/material-kit-react';
const headerStyle = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      border: '0',
      borderRadius: '3px',
      marginBottom: '20px',
      color: '#555',
      backgroundColor: 'white',
      boxShadow:
        '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    },
    moneyAccepter: {
      background: 'white',
      padding: '10px',
      height: '200px',
      marginLeft: '15px',
      width: '240px',
    },
    mobileTerminalWindow: {
      display: 'none',
      transform: 'scale(0.3)',
      position: 'fixed',
      width: '309px',
      height: '231px',
      left: '-73px',
      top: '17px',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    fabTerminal: {
      position: 'fixed',

      bottom: '10px',
      right: '10px',
      background: 'rgb(255, 213, 0)',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        background: 'rgb(255, 213, 0)',
      },
    },
    displayTerminalWindow: {
      display: 'block !important',
    },
    closeIcon: {
      position: 'fixed',
      right: '25px',
      top: '25px',
      cursor: 'pointer',
      fill: '#ffffff',
      height: '35px',
      width: '35px',
    },
    fabTerminalWindow: {
      bottom: '-172px',
      right: '-333px',
      transitionProperty: 'top, left',
    },
    fabTerminalWindowExpanded: {
      display: 'flex !important',
      left: '50%',
      transform: 'translate(-50%) scale(1) !important',
      background: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      zIndex: 9999,
    },
    frameSmallSize: {
      transform: 'scale(0.85)',
    },
    terminalWindowClose: {
      position: 'fixed',
      right: '40px',
      top: '40px',
      zIndex: 9999,
      color: '#ffffff',
      cursor: 'pointer',
    },
    selectWrapper: {
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: '45px',
      },
      '& .MuiSelect-select': {
        width: '42px',
      },
    },
    modalInput: {
      marginLeft: '15px',
      width: '60px',
    },
    terminalWindowWrapper: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    clickWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      cursor: 'pointer',
    },

    fabTerminalControlPage: {
      display: 'block !important',
      bottom: 'auto',
      right: 'auto',
      top: '-162px',
      left: '-14px',
      [theme.breakpoints.down('xs')]: {
        left: '-326px',
        top: '-171px',
      },
      // transition: 'left 5s cubic-bezier(0, 0, 1, 1) !important',
    },
    terminalWindow: {
      display: 'none',
      position: 'fixed',
      cursor: 'pointer',
      // transition: 'left 5s cubic-bezier(0, 0, 1, 1) !important',
      transform: 'scale(0.32)',
      [theme.breakpoints.down('xs')]: {
        transform: 'scale(0.3)',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    title: {
      ...defaultFont,
      lineHeight: '30px',
      fontSize: '18px',
      borderRadius: '3px',
      textTransform: 'none',
      color: 'inherit',
      padding: '8px 16px',
      letterSpacing: 'unset',
      '&:hover,&:focus': {
        color: 'inherit',
        background: 'transparent',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    circle: {
      width: '7px',
      height: '7px',
      borderRadius: '50%',
      marginLeft: '7px',
    },
    statusSuccess: {
      background: 'green',
    },
    switch: {
      padding: '10px',
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: '#008000',
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#5ed44f',
      },
    },

    statusFailed: {
      background: '#f50057',
    },
    statusWrapper: {
      margin: '0px 0px 0px auto',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default headerStyle;
