import { makeStyles, createStyles } from '@material-ui/core/styles';

const alertStyle = makeStyles(() =>
  createStyles({
    snackbarWrapper: {
      '& .MuiSnackbarContent-root': {
        background: '#f44336',
      },

    },
  })
);

export default alertStyle;
