/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from 'components/UI/CustomButtons/regularButton';
import GridContainer from 'components/UI/Grid/GridContainer';
import GridItem from 'components/UI/Grid/GridItem';
import { HeaderContext } from 'contexts/HeaderContext';

const Test = (): JSX.Element => {
  const [reqJson, setReqJson] = useState({});
  const [output, setOutput] = useState('{}');
  const [isReqSended, setReqSended] = useState(false);
  const { state } = useContext(HeaderContext);
  const { isConnectedToSocket, commandResult, sendCommand } = state;
  const defaultProps = {
    rowsMin: 30,
    // defaultValue: '',
    style: { width: '100%' },
  };

  useEffect(() => {
    setOutput('{}');
  }, []);

  useEffect(() => {
    const response = { ...commandResult };
    const payload = JSON.stringify(commandResult.payload, null, 2);
    response.payload = payload;
    if (isReqSended) {
      setOutput(JSON.stringify(response, null, 2));
    }
  }, [isReqSended]);

  const send = () => {
    if (isConnectedToSocket) {
      sendCommand(reqJson);
      setReqSended(true);
    } else {
      alert('Нет коннекта к сокету');
    }
  };

  return (
    <GridContainer>
      <GridItem xs={1} sm={1} md={12}>
        <Button color="primary" onClick={send}>
          Отправить
        </Button>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <TextareaAutosize
          placeholder="Json request"
          {...defaultProps}
          onChange={(e) => setReqJson(e.target.value)}
        />
      </GridItem>
      <GridItem xs={1} sm={11} md={6}>
        <TextareaAutosize
          placeholder="Response"
          value={output}
          {...defaultProps}
        />
      </GridItem>
    </GridContainer>
  );
};

export default Test;
