import React from 'react';
import Pagination from 'rc-pagination';
import './PaginationStyles.css';
interface PaginationProps {
  total: number;
  current: number;
  setPage: (current: number, pageSize: number) => void;
}

const PaginationComponent = ({
  total,
  current,
  setPage,
}: PaginationProps): JSX.Element => {
  if (total) {
    return (
      <Pagination
        current={current}
        defaultPageSize={10}
        pageSize={1}
        total={total}
        onChange={setPage}
        showTotal={(pageTotal) => `Всего страниц:  ${pageTotal}`}
      />
    );
  }

  return <div />;
};

export default PaginationComponent;
