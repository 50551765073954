import React, { FunctionComponent } from 'react';
import Test from 'components/Views/Test/Test';
import Review from 'components/Views/Review/Review';
import Control from 'components/Views/Control/Control';
import Equipment from 'components/Views/Equipment/Equipment';
import Help from 'components/Views/Help/Help';
import Requests from 'components/Views/Requests/Requests';
import { RouteComponentProps } from 'react-router';
import { matchPath } from 'react-router-dom';

interface AdminPanelProps extends RouteComponentProps {
  param2?: string;
}

const AdminPanel: FunctionComponent<AdminPanelProps> = (props) => {
  const pathname = props.location.pathname;
  const matchProfile = matchPath<{ id: string }>(pathname, {
    path: `/admin/:id`,
  });
  const link = matchProfile?.params.id;

  const displayComponents = () => {
    switch (link) {
      case 'review':
        return <Review />;
      case 'equipment':
        return <Equipment />;
      case 'requests':
        return <Requests />;
      case 'help':
        return <Help />;
      case 'control':
        return <Control />;
      case 'test':
        return <Test />;
      default:
        break;
    }
  };

  return <div>{displayComponents()}</div>;
};

export default AdminPanel;
