/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from 'components/UI/Grid/GridContainer';
import GridItem from 'components/UI/Grid/GridItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ButtonMU from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from 'components/UI/CustomButtons/regularButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TelegramIcon from '@material-ui/icons/Telegram';
import DescriptionIcon from '@material-ui/icons/Description';
import Collapse from '@material-ui/core/Collapse';
import { RequestsContext,RequestsProvider } from 'contexts/RequestsContext';
import Pagination from 'components/UI/Pagination/PaginationComponent';
import { BOOKMARKS_ID, DOC_URL } from 'constants/common';
import { HeaderContext } from 'contexts/HeaderContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    requestTimeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    listItemWrapper: {
      wordBreak: 'break-word',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    requestContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-start',
        '& label': {
          left: 'auto',
        },
      },
    },
    reqInfo: {
      border: '1px solid rgba(0, 0, 0, 0.08)',
      fontSize: '12px',
      wordBreak: 'break-all',
    },
    doneButton: {
      color: '#4caf50',
    },
    listContainerWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    alert: {
      padding: '6px 16px',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '12px',
      },
    },
    p: {
      padding: '0 17px',
    },
    linkButton: {
      fontSize: '12px',
    },
    reqPreview: {
      fontFamily: 'monospace',
    },
  })
);

const filterOptions = [
  { type: 0, title: '-' },
  { type: 1, title: 'Авторизация' },
  { type: 3, title: 'Платеж' },
  { type: 9, title: 'Список провайдеров' },
  { type: 10, title: 'Профили комиссий' },
  { type: 12, title: 'Настройки терминала' },
  { type: 14, title: 'Онлайн-проверка' },
  { type: 21, title: 'Доп. инф. по сервису' },
  { type: 36, title: 'Список горячих кнопок' },
  { type: 45, title: 'Группы сервисов' },
];

const Requests: FunctionComponent = (): JSX.Element => {
  const requestsPerPage = 10;
  const classes = useStyles();
  const { requests } = useContext(RequestsContext).state;
  const totalPageCount = Math.floor(requests.count / requestsPerPage);
  const [openedKey, setOpenKey] = useState(0);
  const [page, setPage] = useState(1);
  const [copiedReqId, setCopiedReqId] = useState(0);
  const [copiedRespId, setCopiedRespId] = useState(0);
  const [reqTypeForFilter, setReqTypeForFilter] = useState(0);
  const { sendCommand } = useContext(HeaderContext).state;

  useEffect(() => {
    getRequests();
  }, [page]);

  const handleListItemClick = (key: number) => {
    if (openedKey === key) {
      setOpenKey(0);
    } else {
      setOpenKey(key);
    }
  };

  const getRequests = useCallback(() => {
    const offset = page * requestsPerPage - requestsPerPage;

    sendCommand(
      JSON.stringify({
        type: 'GET_REQUEST_LIST',
        payload: { offset, limit: 10 },
      })
    );
  }, [page]);

  const renderRequests = requests.data.map((item, key) => {
    const { type, request, response, timestamp } = item;
    const convertedDate = new Date(timestamp!);
    const idx = key + 1;

    return (
      <React.Fragment key={idx}>
        <ListItem
          button
          onClick={() => handleListItemClick(idx)}
          selected={idx === openedKey}
          className={classes.listItemWrapper}
        >
          <ListItemIcon>
            <TelegramIcon />
          </ListItemIcon>
          <ListItemText>
            <div>
              <div className={classes.requestTimeContainer}>
                <Typography variant="button" gutterBottom>
                  Тип запроса: {type}
                </Typography>
                <span>{convertedDate.toLocaleTimeString()}</span>
              </div>

              <ButtonMU
                className={classes.linkButton}
                color="primary"
                size="small"
                endIcon={<DescriptionIcon />}
                onClick={() =>
                  window.open(`${BOOKMARKS_ID[Number(type)]}`, '_blank')
                }
              >
                Перейти к документации
              </ButtonMU>
              <p className={classes.reqPreview}>
                {request.slice(0, 115).replace(/\n/, '')}
                ...
              </p>
              <p className={classes.reqPreview}>
                {response.slice(0, 115).replace(/\n/, '')}
                ...
              </p>
            </div>
          </ListItemText>
          {idx === openedKey ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={idx === openedKey}
          timeout="auto"
          unmountOnExit
          className={classes.reqInfo}
        >
          <List component="div" disablePadding>
            <ListItem className={classes.nested}>
              <ListItemText>
                <div>
                  <div>
                    <h4>
                      Запрос:
                      <CopyToClipboard
                        text={request}
                        onCopy={() => {
                          setCopiedRespId(0);
                          setCopiedReqId(idx);
                        }}
                      >
                        {copiedReqId === idx ? (
                          <ButtonMU
                            color="primary"
                            size="small"
                            endIcon={<DoneOutlineIcon />}
                            className={classes.doneButton}
                          >
                            Скопировано
                          </ButtonMU>
                        ) : (
                          <ButtonMU
                            color="primary"
                            size="small"
                            endIcon={<FileCopyIcon />}
                          >
                            Скопировать запрос
                          </ButtonMU>
                        )}
                      </CopyToClipboard>
                    </h4>
                    <Typography variant="caption" display="block" gutterBottom>
                      {request.slice(0, 5000).replace(/\n/, '')}
                    </Typography>
                  </div>
                  <div>
                    <h4>
                      Ответ:
                      <CopyToClipboard
                        text={response}
                        onCopy={() => {
                          setCopiedRespId(idx);
                          setCopiedReqId(0);
                        }}
                      >
                        {copiedRespId === idx ? (
                          <ButtonMU
                            color="primary"
                            size="small"
                            endIcon={<DoneOutlineIcon />}
                            className={classes.doneButton}
                          >
                            Скопировано
                          </ButtonMU>
                        ) : (
                          <ButtonMU
                            color="primary"
                            size="small"
                            endIcon={<FileCopyIcon />}
                          >
                            Скопировать ответ
                          </ButtonMU>
                        )}
                      </CopyToClipboard>
                    </h4>
                    <Typography variant="caption" display="block" gutterBottom>
                      {response.slice(0, 5000).replace(/\n/, '')}
                    </Typography>
                  </div>
                </div>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </React.Fragment>
    );
  });

  return (
    <div className={classes.root}>
      <RequestsProvider>
        <FormControl className={classes.formControl}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <>
                <GridContainer
                  justify="flex-start"
                  alignItems="center"
                  className={classes.requestContainer}
                >
                  <GridItem xs={10} sm={10} md={10}>
                    <Button color="primary" onClick={getRequests} size="sm">
                      Обновить список
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => window.open(DOC_URL, '_blank')}
                      size="sm"
                    >
                      Открыть документацию
                    </Button>
                  </GridItem>
                  <GridItem md={2}>
                    <InputLabel id="filter">Фильтр по типу запроса</InputLabel>
                    <Select
                      labelId="filter"
                      value={reqTypeForFilter}
                      onChange={(e) =>
                        setReqTypeForFilter(Number(e.target.value))
                      }
                    >
                      {filterOptions.map(({ type, title }, idx) => (
                        <MenuItem value={type} key={idx}>
                          {type ? `${type}   | ${title}` : 'Выберите из списка'}
                        </MenuItem>
                      ))}
                    </Select>
                  </GridItem>
                  <Alert severity="warning" className={classes.alert}>
                    Чтобы увидеть базовый список запросов (например, получение
                    списка сервисов), выключите и включите терминал в шапке
                    сайта и нажмите на кнопку &quot;Обновить список&quot;
                  </Alert>
                </GridContainer>
              </>
            }
          >
            <div className={classes.listContainerWrapper}>
              {renderRequests.length ? (
                renderRequests
              ) : (
                <p className={classes.p}>
                  Пожалуйста подождите, список запросов загружается
                </p>
              )}
            </div>
          </List>
          <Pagination total={totalPageCount} current={page} setPage={setPage} />
        </FormControl>
      </RequestsProvider>
    </div>
  );
};

export default Requests;
