import React, { FunctionComponent, ReactNode } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      position: 'relative',
      width: '100%',
      minHeight: '1px',
      paddingRight: '15px',
      paddingLeft: '15px',
      flexBasis: 'auto',
    },
  })
);

interface GridItemProps {
  children: ReactNode;
  className?: string;
  xs?: GRID_SIZES;
  sm?: GRID_SIZES;
  md?: GRID_SIZES;
}

type GRID_SIZES =
  | boolean
  | 2
  | 1
  | 'auto'
  | 12
  | 4
  | 3
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | undefined;

const GridItem: FunctionComponent<GridItemProps> = (props) => {
  const classes = useStyles();
  const { children, className = '', ...rest } = props;

  return (
    <Grid item {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
};

GridItem.defaultProps = {
  xs: false,
  sm: false,
  md: false,
};

export default GridItem;
