import React from 'react';

const Icon = ({ type, ...attr }: any) => {
  const icons = {
    terminal: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9052 18.4562H22.8062L17.4082 6.21256H34.4166L39.9052 18.4562ZM17.4082 4.34283C16.7447 4.34283 16.1717 4.6444 15.8099 5.18723C15.448 5.7602 15.4178 6.3935 15.6591 6.99663L21.0873 19.2101C21.3889 19.9339 22.0523 20.3561 22.8062 20.3561H39.9052C40.5686 20.3561 41.1416 20.0244 41.5035 19.4816C41.8653 18.9387 41.8955 18.2753 41.6241 17.6721L36.1657 5.45864C35.834 4.76503 35.2007 4.34283 34.4166 4.34283H17.4082Z"
          fill="#2B2A29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.96374 48.1001H13.0349V18.5766L7.48606 6.36309C7.39559 6.15199 7.36543 5.94089 7.4559 5.69964L9.02406 0.66345C9.14469 0.241255 9.50657 0 9.92876 0H36.708C37.1001 0 37.4318 0.180941 37.5826 0.542823L45.6043 17.9735C45.6646 18.1242 45.6947 18.2449 45.6947 18.3957V49.0651C45.6947 49.5778 45.2726 50 44.7297 50H8.02888C7.51622 50 7.09402 49.5778 7.09402 49.0651V18.5766L2.84191 8.56454C2.66097 8.11218 2.87207 7.5392 3.35458 7.32811C3.83709 7.14717 4.41007 7.35826 4.59101 7.84077L8.90343 18.0036C8.96374 18.1242 8.96374 18.2449 8.96374 18.3957V48.1001ZM14.9348 48.1001H43.7949V18.5766L36.1049 1.86972H10.6224L9.35578 5.91074L14.8443 18.0036C14.9046 18.1242 14.9348 18.2449 14.9348 18.3957V48.1001Z"
          fill="#2B2A29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0643 25.9048C40.115 25.9048 41.8038 26.327 41.8038 26.8396C41.8038 27.3825 40.115 27.8047 38.0643 27.8047C36.0137 27.8047 34.3249 27.3825 34.3249 26.8396C34.3249 26.327 36.0137 25.9048 38.0643 25.9048Z"
          fill="#2B2A29"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.2599 27.8047C34.7472 27.8047 34.325 27.3825 34.325 26.8397C34.325 26.327 34.7472 25.9048 35.2599 25.9048H40.869C41.3817 25.9048 41.8039 26.327 41.8039 26.8397C41.8039 27.3825 41.3817 27.8047 40.869 27.8047H35.2599ZM14.9342 18.3958V48.1002H43.7942V18.5767L36.1043 1.86984H10.6217L9.35516 5.91086L14.8437 18.0037C14.904 18.1244 14.9342 18.245 14.9342 18.3958ZM22.8051 20.356C22.0512 20.356 21.3877 19.9338 21.0862 19.21L15.6579 6.9965C15.4167 6.39336 15.4468 5.76007 15.8087 5.18709C16.1706 4.64427 16.7436 4.3427 17.407 4.3427H34.4155C35.1996 4.3427 35.8328 4.7649 36.1646 5.4585L41.623 17.672C41.8944 18.2751 41.8642 18.9386 41.5023 19.4814C41.1404 20.0242 40.5675 20.356 39.904 20.356H22.8051Z"
          fill="#FFD500"
        />
      </svg>
    ),
  };

  return icons[type] || null;
};

export default Icon;
