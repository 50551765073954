/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Header from './Header/Header';
import LeftMenu from './LeftMenu';
import { DRAWER_WIDTH } from 'constants/common';
import Footer from 'layout/Footer';
import { HeaderContext } from 'contexts/HeaderContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

const AdminLayout = (props: any): JSX.Element => {
  const { state } = useContext(HeaderContext);
  const history = useHistory();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (props.path.includes('admin') && !state.isConnectedToSocket) {
      history.push('/');
    }
  }, []);

  return (
    <div className={classes.root}>
      {props.path.includes('admin') && (
        <>
          <CssBaseline />
          <Header
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
          <LeftMenu
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
        </>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
      <Footer />
    </div>
  );
};

export default AdminLayout;
