import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import HelpControl from 'assets/img/helpControl.png';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Button from '@material-ui/core/Button';
import Iframe from 'react-iframe';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    boxShadow: 'none',
  },
  alert: {
    padding: '6px 16px 16px 16px',
  },
  terminalImage: {
    width: '70%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  terminalWindow: {
    display: 'none',
    position: 'fixed',
    cursor: 'pointer',
  },
  displayTerminalWindow: {
    display: 'block !important',
  },
  terminalWindowWrapper: {
    position: 'relative',
  },
  fabTerminalWindowExpanded: {
    display: 'flex !important',
    left: '50%',
    top: 0,
    transform: 'translate(-50%) scale(1) !important',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    zIndex: 9999,
  },
  closeIcon: {
    position: 'fixed',
    right: '150px',
    top: '50px',
    cursor: 'pointer',
    fill: '#ffffff',
    height: '35px',
    width: '35px',
  },
}));

const Help = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [showFrame, setShowFrame] = useState(false);
  const wrapperRef: any = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowFrame(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getListItem = (title: string, description?: string) => {
    const listItemProps = {
      primary: title,
      secondary: description || '',
    };

    return (
      <ListItem>
        <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>
        <ListItemText {...listItemProps} />
      </ListItem>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{ style: { background: 'rgb(255, 213, 0)' } }}
        >
          <Tab label="Управление" />
          <Tab label="Оборудование" />
          <Tab label="Запросы" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {showFrame && (
          <div
            className={`
              ${classes.terminalWindow}
              ${classes.fabTerminalWindowExpanded}
              ${classes.displayTerminalWindow}
              `}
          >
            <div ref={wrapperRef} className={classes.terminalWindowWrapper}>
              <Iframe
                url={`${process.env.PUBLIC_URL}/static/video.mp4`}
                width="1030px"
                height="570px"
                allowFullScreen
              />
              <CloseIcon
                onClick={() => setShowFrame(false)}
                className={classes.closeIcon}
              />
            </div>
          </div>
        )}
        <Button
          color="primary"
          size="small"
          startIcon={<PlayCircleOutlineIcon />}
          onClick={() => setShowFrame(true)}
        >
          Посмотреть видео-пример оплаты
        </Button>
        <Typography variant="body1" gutterBottom>
          На странице &quot;Управление&quot; Вы можете наглядно увидеть принцип
          работы терминала самообслуживания.
        </Typography>
        <br />
        <img
          src={HelpControl}
          className={classes.terminalImage}
          alt="Терминал"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="button" gutterBottom>
          Данный раздел позволяет посмотреть статус оборудования виртуального
          терминала
        </Typography>
        <List>
          {getListItem(
            'Соединение с сервером',
            'Отображает статус соединения с сервером'
          )}
          {getListItem(
            'Состояние терминала',
            'Отображает статус виртуального терминала (включен/выключен)'
          )}
          {getListItem(
            'Купюроприёмник',
            'Отображает номиналы и количество купюр'
          )}
          {getListItem(
            'Монетоприёмник',
            'Отображает номиналы и количество монет'
          )}
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Alert severity="warning" className={classes.alert}>
          Чтобы увидеть базовый список запросов (например, получение списка
          сервисов), выключите и включите терминал в шапке сайта и нажмите на
          кнопку &quot;Обновить список&quot;
        </Alert>
        <br />
        <Typography variant="body1" gutterBottom>
          При проведении платежа будут выполняться такие запросы как
          онлайн-проверка и запрос на платеж. В данном разделе можно будет
          увидеть тело запроса и ответа.
        </Typography>
        <br />
        <br />
        <Typography variant="button" gutterBottom>
          Возможности:
        </Typography>
        <List>
          {getListItem('Копирование тела запроса и ответа')}
          {getListItem('Фильтрация запросов по типу')}
          {getListItem(
            'Подгрузка списка запросов по нажатию на кнопку "Обновить список"'
          )}
        </List>
      </TabPanel>
    </div>
  );
};

export default Help;
