export const DOC_URL =
  'https://docs.google.com/document/d/1SXHoepHMwkB0Qkoty5733_hYFSC9bK9PRj3xx2T2SGw/edit';

export const DRAWER_WIDTH = 240;
export const BOOKMARKS_ID = {
  1: `${DOC_URL}#bookmark=id.wxm2ua4j7ujq`,
  2: `${DOC_URL}#bookmark=id.4yjm4gdj3xt3`,
  3: `${DOC_URL}#bookmark=id.2ko58mozaf5r`,
  4: `${DOC_URL}#bookmark=id.xch35vzf7m1y`,
  5: `${DOC_URL}#bookmark=id.mhlia966ibhz`,
  6: `${DOC_URL}#bookmark=id.8rqo02augtn2`,
  7: `${DOC_URL}#bookmark=id.uyiirut9urrn`,
  8: `${DOC_URL}#bookmark=id.jfjly6m3wyp`,
  9: `${DOC_URL}#bookmark=id.uroaj7vwek3x`,
  10: `${DOC_URL}#bookmark=id.b1xcc95by33a`,
  11: `${DOC_URL}#bookmark=id.i8dlxxmlcsl6`,
  12: `${DOC_URL}#bookmark=id.z5c0rvt0rldj`,
  13: `${DOC_URL}#bookmark=id.puw69v7frt5v`,
  14: `${DOC_URL}#bookmark=id.mv2qhfunu4lg`,
  21: `${DOC_URL}#bookmark=id.91jkkad1zbob`,
  26: `${DOC_URL}#bookmark=id.9ed6urdcw6mk`,
  31: `${DOC_URL}#bookmark=id.v502t57kh9qv`,
  36: `${DOC_URL}#bookmark=id.ovf0mhmsmxz`,
  45: `${DOC_URL}#bookmark=id.ymzf6neurvv`,
};
