import React, { FunctionComponent, useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ReactJson from 'react-json-view';
import { HeaderContext } from 'contexts/HeaderContext';

const useStyles = makeStyles(() =>
  createStyles({
    equipmentWrapper: {
      display: 'flex',
    },
    equipmentView: {
      width: '40%',
      border: '1px solid black',
      display: 'flex',
      flexFlow: 'column',
      padding: '20px',
    },
    equipmentRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

type EquipmentProps = {
  type: string,
};

const Equipment: FunctionComponent = (): JSX.Element => {
  const { state } = useContext(HeaderContext);
  const { terminalInfo } = state;

  const classes = useStyles();

  return (
    <div className="animation">
      <div className={classes.equipmentWrapper}>
        {Object.keys(terminalInfo).length > 0 && (
          <ReactJson
            src={terminalInfo}
            collapsed={true}
            enableClipboard={false}
            groupArraysAfterLength={200}
          />
        )}
      </div>
    </div>
  );
};

export default Equipment;
