import React, { useContext } from 'react';
import TerminalIcon from 'components/UI/Icon/icon';
import useStyles from './loaderStyle';
import { HeaderContext } from 'contexts/HeaderContext';

const Loader = (): JSX.Element => {
  const { state } = useContext(HeaderContext);
  const { showLoader } = state;
  const classes = useStyles();

  const dispalyLoader = () => {
    if (showLoader) {
      return (
        <div className={classes.loaderWrapper}>
          <div className={classes.logoSpinner}>
            <div className={classes.loaderComponent} />
            <p>
              <TerminalIcon type="terminal" />
            </p>
          </div>
        </div>
      );
    }
    return <div />;
  };
  return dispalyLoader();
};

export default Loader;
