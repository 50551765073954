export const getClassName = (
  location: any,
  classes: any,
  isTerminalWindowExpanded: boolean
): any => {
  if (location.pathname !== '/admin/control') {
    return classes.fabTerminalWindow;
  } else {
    if (isTerminalWindowExpanded) {
      return classes.fabTerminalWindowExpanded;
    }
    return classes.fabTerminalControlPage;
  }
};

export const getUrl = (
  isCoreRequestFinished: boolean,
  terminalStatus: any,
  terminalID: number
): any => {
  if (isCoreRequestFinished && terminalStatus) {
    return `/shell/shell.htm?terminal_id=${terminalID}`;
  }
  return '/shell/notwork.htm';
};
