type Action =
  | { type: 'ADD_REQUEST', requests: RequestType }
  | { type: 'MONEY_WAS_INSERTED', moneyWasInserted: boolean }
  | { type: 'RESET_REQUESTS' };

type State = {
  requests: RequestType,
  moneyWasInserted: false,
};

type RequestType = {
  offset: number,
  limit: number,
  count: number,
  data: RowType[],
};

type RowType = {
  type: number,
  request: string,
  response: string,
};

const MAX_REQUESTS_COUNT = 100;

const requests = (state: State, action: Action): any => {
  switch (action.type) {
    case 'ADD_REQUEST': {
      if (state.requests.data.length < MAX_REQUESTS_COUNT) {
        return {
          ...state,
          requests: action.requests,
        };
      }

      return state;
    }
    case 'RESET_REQUESTS': {
      return { ...state, requests: [] };
    }
    case 'MONEY_WAS_INSERTED': {
      return { ...state, moneyWasInserted: action.moneyWasInserted };
    }
    default:
      return state;
  }
};

export default requests;
