import React, { FunctionComponent } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import './index.css';
import AdminLayout from 'layout';
import Alert from 'components/UI/Alert/alert'
import Admin from 'components/Views';
import EntryPage from 'components/Views/EntryPage/EntryPage';
import Loader from 'components/UI/Loader/Loader';

type AppPathProps = {
  component: React.ComponentType<RouteComponentProps>,
  layout: FunctionComponent,
  path: string,
  exact?: boolean,
};

const AppPath: FunctionComponent<AppPathProps> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(test) => {
        const props = { ...test };
        const layoutProps = {
          path: props.match.path,
        };

        return (
          <Layout {...layoutProps}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

const Routes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Loader />
      <Alert/>
      <Switch>
        <AppPath exact path="/" component={EntryPage} layout={AdminLayout} />
        <AppPath path="/admin" component={Admin} layout={AdminLayout} />
        <AppPath path="/admin:id" component={Admin} layout={AdminLayout} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
