/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useContext } from 'react';
import GridContainer from 'components/UI/Grid/GridContainer';
import GridItem from 'components/UI/Grid/GridItem';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Typography from '@material-ui/core/Typography';
import useStyles from './entryPageStyle';
import terminalImage from 'assets/img/terminal.jpg';
import { HeaderContext } from 'contexts/HeaderContext';

const options = [
  { name: 'Касса24', theme: 'cashbox24' },
  { name: 'Форте', theme: 'forte' },
  { name: 'Юникасса', theme: 'unikassa' },
  { name: 'ВТБ', theme: 'vtb' },
];

const EntryPage: FC = ({ history }: any) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(HeaderContext);
  const { isConnectedToSocket, commandResult, sendRequest } = state;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: { target: any }) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const goToAdminPage = () => {
    dispatch({ type: 'ENABLE_LOADER' });
    if (isConnectedToSocket) {
      sendRequest('CREATE_TERMINAL', options[selectedIndex].theme);
    }
  };

  useEffect((): any => {
    if (commandResult.type === 'CREATE_TERMINAL') {
      history.push('/admin/control');
    }
  }, [commandResult]);

  return (
    <div className={classes.container}>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={3} md={3} className={classes.terminalBlock}>
          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <Paper elevation={4} className={classes.paper}>
              <img
                src={terminalImage}
                className={classes.terminalImage}
                alt="Терминал"
              />
            </Paper>
          </Slide>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Typography variant="h5" gutterBottom>
            Добро пожаловать в имитатор терминала
          </Typography>
          <Typography variant="body1" gutterBottom>
            Модуль позволяет имитировать работу реального терминала
            самообслуживания в вашем браузере
          </Typography>
          <div>
            <ButtonGroup
              variant="contained"
              className={classes.entryButton}
              ref={anchorRef}
              aria-label="split button"
            >
              <Button
                className={classes.entryButton}
                size="large"
                onClick={goToAdminPage}
                disabled={!isConnectedToSocket}
              >
                {options[selectedIndex].name}
              </Button>
              <Button
                className={classes.entryButton}
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => handleMenuItemClick(index)}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default EntryPage;
