import React, { FunctionComponent, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = {
  grid: {
    marginRight: '15px',
    marginLeft: '15px',
    width: 'auto',
  },
};

interface GridProps {
  className?: string;
  children: ReactNode;
  justify?: JustifyType;
  alignItems?: AlignItemsType;
  direction?: DirectionType;
}

type JustifyType =
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | undefined;

type AlignItemsType =
  | 'stretch'
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'baseline'
  | undefined;

type DirectionType =
  | 'row'
  | 'column'
  | 'column-reverse'
  | 'row-reverse'
  | undefined;

const GridContainer: FunctionComponent<GridProps> = (props) => {
  const { children, className = '', ...rest } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      className={classes.grid + ' ' + className}
      {...rest}
    >
      {children}
    </Grid>
  );
};

GridContainer.defaultProps = {
  justify: 'flex-start',
  alignItems: 'flex-start',
  direction: 'row',
};

export default GridContainer;
