import React, { useContext } from 'react';
import useStyles from './alertStyle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { HeaderContext } from 'contexts/HeaderContext';

const ErrorMessagesTranslate = {
  PAYMENT_NOT_INITIATED: 'Платёж не инициализирован',
  SERVICE_IS_NOT_SPECIFIED: 'Не указан сервис',
  EQUIPMENT_IS_TURNED_OFF: 'Оборудование отключено',
  UNSUPPORTED_COMMAND: 'Неподдерживаемая на данный момент команда',
  CANNOT_FIND_SERVICE_BY_ID: 'Невозможно найти сервис по идентификатору',
  OVERFLOW: 'Переполнение буфера запросов объекта модема',
  UNKNOWN: 'Неизвестная ошибка',
  ALREADY_TURNED_ON: 'Оборудование уже включено',
  NOT_ENOUGH_BALANCE: 'Недостаточно баланса',
};

const Alert = (props: any) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(HeaderContext);

  const { isTerminalError, errorMessage } = state;
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: 'SET_TERMINAL_ERROR', isTerminalError: false });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isTerminalError}
        autoHideDuration={6000}
        className={classes.snackbarWrapper}
        onClose={handleClose}
        message={ErrorMessagesTranslate[errorMessage]}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default Alert;
