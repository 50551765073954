/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { HeaderContext } from 'contexts/HeaderContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './reviewStyle';

type NominalProps = {
  [key: string]: [],
};

const Review = (props: any): JSX.Element => {
  const [open, setopen] = useState(999);
  const { dispatch, state } = useContext(HeaderContext);
  const {
    isConnectedToServer,
    terminalInfo,
    sendCommand,
    terminalStatus,
    commandResult,
  } = state;
  const { equipment }: any = terminalInfo;
  const classes = useStyles();

  useEffect(() => {
    sendCommand(JSON.stringify({ type: 'GET_STATE' }));
  }, [sendCommand, terminalStatus]);

  useEffect(() => {
    dispatch({
      type: 'SET_TERMINAL_INFO',
      terminalInfo: commandResult.result,
    });
  }, [commandResult]);

  const handleRowClick = (idx: number, type: string) => {
    setopen(idx);
    if (idx === open) {
      setopen(999);
    }
  };

  const displayNominal = (nominal: any, supportedNominal: any) => {
    return (
      <div className={classes.nominalWrapper}>
        <div className={classes.nominalRow}>
          <span>Номинал</span>
          <span>Кол-во</span>
        </div>
        {supportedNominal.map((item: any, idx: number) => {
          let amountNominal = '0';
          if (item in nominal) {
            amountNominal = nominal[item];
          }

          return (
            <div className={classes.nominalRow} key={idx}>
              <span>{item}</span>
              <span> {amountNominal}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        className={classes.reviewTableContainer}
      >
        <Table className={classes.tableWrapper}>
          <TableBody>
            {/* <TableRow>
              <TableCell align="left">Оболочка терминала</TableCell>
              <TableCell align="right">
                <Link
                  target="_blank"
                  to={`/shell/shell.htm?terminal_id=${terminalID}`}
                >
                  <Chip
                    label="Открыть"
                    style={{ background: '#ffd500', cursor: 'pointer' }}
                    className={classes.chipElement}
                    icon={<OpenInBrowserRoundedIcon />}
                  />
                </Link>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell align="left">Соединение с сервером</TableCell>
              <TableCell align="right">
                <Chip
                  label={isConnectedToServer ? 'Подключен' : 'Не подключен'}
                  className={classes.chipElement}
                  icon={
                    isConnectedToServer ? (
                      <CheckCircleRoundedIcon style={{ color: '#008000' }} />
                    ) : (
                      <CancelIcon style={{ color: '#f50057' }} />
                    )
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Состояние терминала</TableCell>
              <TableCell align="right">
                <Chip
                  label={
                    isConnectedToServer && terminalStatus
                      ? 'Подключен'
                      : 'Не подключен'
                  }
                  className={classes.chipElement}
                  icon={
                    isConnectedToServer && terminalStatus ? (
                      <CheckCircleRoundedIcon style={{ color: '#008000' }} />
                    ) : (
                      <CancelIcon style={{ color: '#f50057' }} />
                    )
                  }
                />
              </TableCell>
            </TableRow>
            {equipment &&
              equipment.map((item: { [key: string]: string }, idx: number) => {
                const { name, isTurnedOn, type } = item;

                if (type === 'COIN_ACCEPTOR' || type === 'BILL_ACCEPTOR') {
                  return (
                    <TableRow
                      onClick={() => {
                        handleRowClick(idx, type);
                      }}
                      key={idx}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell align="left">
                        {name}{' '}
                        <Collapse in={open === idx}>
                          {displayNominal(
                            item._nominal,
                            item._supportedNominal
                          )}
                        </Collapse>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.nominalRowWrapper}
                      >
                        <Chip
                          label={isTurnedOn ? 'Подключен' : 'Не подключен'}
                          className={classes.chipElement}
                          icon={
                            isTurnedOn ? (
                              <CheckCircleRoundedIcon
                                style={{ color: '#008000' }}
                              />
                            ) : (
                              <CancelIcon style={{ color: '#f50057' }} />
                            )
                          }
                        />
                        <ExpandMoreIcon
                          className={`${classes.nominalCellIcon} ${
                            open === idx && classes.rotate
                          }`}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
                return (
                  <TableRow key={idx}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="right">
                      <Chip
                        label={isTurnedOn ? 'Подключен' : 'Не подключен'}
                        className={classes.chipElement}
                        icon={
                          isTurnedOn ? (
                            <CheckCircleRoundedIcon
                              style={{ color: '#008000' }}
                            />
                          ) : (
                            <CancelIcon style={{ color: '#f50057' }} />
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Review;
