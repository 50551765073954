import { makeStyles, createStyles,Theme } from '@material-ui/core/styles';

const printerCommon = {
  backgroundColor: 'gray',
  borderStyle: 'solid',
  borderRadius: '10px',
  boxShadow:
    '0 -5px 5px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -5px 5px rgba(100, 100, 100, 0.1), inset 0 5px 5px rgba(255, 255, 255, 0.3)',
};

const СontrolStyle = makeStyles((theme: Theme) =>
  createStyles({
    terminalFlat: {
      borderBottom: '100px solid #b0b0af',
      borderLeft: '25px solid transparent',
      borderRight: '25px solid transparent',
      height: '0',
      width: '435px',
      position: 'absolute',
      left: '-29px',
      top: '547px',
      zIndex: -1,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    '@keyframes blinkYellow': {
      from: { backgroundColor: '#FF0' },
      '50%': {
        backgroundColor: '#AA0',
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 0',
      },
      to: { backgroundColor: '#FF0' },
    },

    flashLight: {
      position: 'absolute',
      top: '401px',
      left: '30px',
      margin: '0 auto',
      width: '12px',
      height: '12px',
      backgroundColor: '#FF0',
      borderRadius: '50%',
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 12px',
    },
    blinkAnimation: {
      animation: '$blinkYellow 1s infinite',
    },

    paperHolder: {
      position: 'absolute',
      overflow: 'hidden',
      height: '0',
      top: '3px',
      left: '0',
      right: '0',
      transition: '0',
    },

    paperHolderAnimation: {
      transition: 'height 3s linear',
    },

    closeIcon: {
      position: 'fixed',
      right: '25px',
      top: '25px',
      cursor: 'pointer',
      fill: '#ffffff',
      height: '35px',
      width: '35px',
    },
    paper: {
      position: 'absolute',
      width: '72px',
      left: '5px',
      bottom: '0',
      height: '60px',
      lineHeight: '30px',
      textAlign: 'center',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      boxShadow: '2px 2px 2px rgba(0,0,0,0.1)',
      borderTop: '0',
      '& svg': {
        width: '24px',
      },
    },
    receiptIcon: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      '& img': {
        width: '60px',
      },
    },
    contentReceiptWrapper: {
      '& img': {
        width: '250px',
      },
    },
    print: {
      height: '60px',
    },
    coin: {
      top: '310px',
      left: '160px',
      width: '55px',
      height: '85px',
      border: '25px',
      borderColor: 'black',
      position: 'absolute',
      cursor: 'pointer',
      ...printerCommon,
      [theme.breakpoints.down('xs')]: {
        left: '140px',
      },
    },
    cash: {
      top: '310px',
      left: '250px',
      width: '100px',
      height: '25px',
      border: '10px',

      borderColor: '#00A859',
      position: 'absolute',
      cursor: 'pointer',
      ...printerCommon,
      [theme.breakpoints.down('xs')]: {
        width: '85px',
        left: '233px',
      },
    },
    terminalWrapper: {
      position: 'relative',
      marginLeft: '50px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0',
      },

    },
    terminalFrameInit: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%) scale(1) !important',
    },
    expandFrame: {
      // transform:'scale(0.32)'
    },
    expandButton: {
      zIndex: 9999,

      transform: 'none',
    },
    terminalFrame: {
      borderRadius: '17px',
      transform: 'scale(0.32)',
      zIndex: 9,
      top: '0',
    },
    Terminal: {
      left: '265px',
      display: 'flex',
      width: '375px',
      height: '600px',
      background: 'linear-gradient(0deg, #FFDE16 0%, #FFF229 100%)',
      borderRadius: '10px',
      justifyContent: 'center !important',
      [theme.breakpoints.down('xs')]: {
        width: '330px',
      },
    },
    power: {
      position: 'absolute',
      top: '390px',
      left: '51px',
      boxSizing: 'border-box',
      width: '30px',
      height: '30px',
      padding: '6px',
      borderRadius: '16px',
      backgroundColor: '#c4eec4',
      boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.5)',
      cursor: 'pointer',
    },
    powerLight: {
      width: '18px',
      height: '18px',
      borderRadius: '11px',
      background:
        'radial-gradient(ellipse at center, #00fe4f 0%, #11d524 30%,#11d524 100%)',
    },
    soundControlIcon: {
      width: '18px',
      height: '18px',
      borderRadius: '11px',
      background:
        'radial-gradient(ellipse at center, #ffffff 0%, #ffffff 30%,#ffffff 100%)',
    },
    soundControlButton: {
      position: 'absolute',
      top: '390px',
      left: '90px',
      background: '#ffffff',
      minWidth: 'auto',
      outline: 'none',
      cursor: 'pointer',
      boxSizing: 'border-box',
      width: '30px',
      height: '30px',
      padding: '6px',
      borderRadius: '16px',
      boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.5)',
    },
    mainScreen: {
      display: 'flex',
      width: '375px',
      height: '270px',
      border: '1px',
      borderColor: '#c5bbbb',
      borderStyle: 'solid',
      borderRadius: '10px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    screen: {
      width: '315px',
      height: '220px',
      backgroundColor: 'gray',
      borderRadius: '10px',
    },
    modalWrapper: {
      // width:'500px'
      '& .MuiDialog-paperWidthSm': {
        width: '377px',
      },
    },
    printer: {
      top: '310px',
      left: '25px',
      width: '100px',
      height: '25px',
      position: 'absolute',
      border: '10px',
      borderColor: 'black',
      cursor: 'pointer',
      ...printerCommon,
      [theme.breakpoints.down('xs')]: {
        width: '85px',
        left: '14px',
      },
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },
    selectWrapper: {
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: '45px',
      },
      '& .MuiSelect-select': {
        width: '42px',
      },
    },
    dialogActionWrapper: {
      justifyContent: 'space-around',
    },
    modalInput: {
      marginLeft: '15px',
    },

    logo: {
      position: 'absolute',
      top: '480px',
      width: '500px',
      height: '320px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    popup: {
      position: 'absolute',
      top: '200px',
      left: '85px',
      width: '210px',
      height: '100px',
      backgroundColor: 'white',
      textAlign: 'center',
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '20px',
      borderRadius: '5px',
      border: '0',
      borderColor: 'black',
      borderStyle: 'solid',
      /* display: none, */
    },
    valueCoin: {
      position: 'absolute',
      top: '7px',
      left: '15px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
    },
    quantity: {
      position: 'absolute',
      top: '7px',
      left: '125px',
      fontFamily: '"Roboto", sans-serif',
      fontSize: '14px',
    },
    selecCoin: {
      position: 'absolute',
      top: '30px',
      left: '25px',
    },
    selQuantity: {
      position: 'absolute',
      top: '30px',
      left: '127px',
      width: '50px',
      height: '19px,',
    },
    result: {
      position: 'absolute',
      top: '47px',
      left: '95px',
    },
    button: {
      position: 'absolute',
      top: '75px',
      left: '65px',
    },
    close: {
      position: 'absolute',
      left: '190px',
      top: '1px',
    },
    select: {
      fontSize: '20px',
      '& option': {
        fontSize: '15px',
      },
    },
  })
);

export default СontrolStyle;
